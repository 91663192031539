import Vue from 'vue'
import App from './App.vue'
import router from './plugins/router';
import vuetify from './plugins/vuetify';
import { i18n } from './plugins/i18n';
import Snotify from 'vue-snotify';
import '@/assets/scss/app.scss';


Vue.config.productionTip = false;
const SnotifyOptions = {
  global: {
    showProgressBar: false,
    newOnTop: true,
    oneAtTime: false,
    preventDuplicates: true,
  },
};
Vue.use(Snotify, SnotifyOptions);

new Vue({
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
