<script>
import { API, API_GET_PIN_CODE, API_MOBIL_STATUS, API_CHECK_MANAGER, API_CHECK_USER, CLIENT_URL} from "./../plugins/config";

export default {
  data() {
    return {
      pinpassword: "    ",
      isLoading: false,
      hasThePassword: false,
      mobilAppInstalled: false,
      token: null,
      code: null,
      errormsg: null,
      hideMsg: true,
      steps: 0,
      timer: 120,
      stepsInfoTitle: "",
      totalStep: 0,
      currentStep: 0,
      demoId: null,
      urlCode: null,
      isRetry: false,
      banToLeave: true,
    };
  },
  computed: {
    is_required() {
      return [v => !!v || this.$i18n.t("field_required")];
    },
    email_rules() {
      return [
        v => !!v || this.$i18n.t("field_required"),
        v =>
          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(
            v
          ) || this.$i18n.t("need_valid_email")
      ];
    },
    password_rules() {
      return [
        v => !!v || "Password is required",
        v => (v && v.length > 3) || "Password must have more than 4 characters"
        // v => /(?=.*[A-Z])/.test(v) || "Must have one uppercase character",
        // v => /(?=.*\d)/.test(v) || "Must have one number"
      ];
    },
    hours() {
      return Math.floor(Math.abs(this.timer) / 3600);
    },
    minutes() {
      return Math.floor((Math.abs(this.timer) - this.hours * 3600) / 60);
    },
    seconds() {
      return Math.abs(this.timer) % 60;
    }
  },
  created() {
    window.addEventListener('beforeunload', (e) => {
          if (this.banToLeave) {
            e.preventDefault();
            e.returnValue = '';
          } else {
            console.log('good to go!');
          }
    }, false);
  },
  methods: {
    async checkValidation() {
      const res = this.$refs.Form.validate();
      if (res) {
        return true;
      }
      this.hideMsg = false;
      return false;
    },

    removeListener() {
      const that = this;
      return new Promise((resolve)=> {
        that.banToLeave = false;
        that.isLoading = true;
        setTimeout(() => {
          resolve();
        }, 750);
      });

    },

    displayError(error) {
      let msg;
      if (error && error.response) {
        if (error.response.data) {
          msg = error.response.data.StatusMessage;
        } else {
          msg = error.response;
        }
      } else if (typeof error === "string") {
        msg = error;
      } else {
        msg = error.data.StatusMessage;
      }
      if (!msg) msg = JSON.stringify(error).slice(80);
      this.$snotify.error(msg, this.$t("error"), {
        showProgressBar: false,
        closeOnClick: true,
        timeout: 0,
        pauseOnHover: false,
        buttons: [
          {
            text: this.$t("i_got_it"),
            action: toast => {
              this.$snotify.remove(toast.id);
            }
          }
        ]
      });
    },

    getPincode() {
      this.isLoading = true;

        return new Promise((resolve, reject) => {
          API.get(`${API_GET_PIN_CODE}`, {
            headers: {
              "content-type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${this.token}`
            }
          })
            .then(response => {
              if (response.data.Pin) {
                this.pinpassword = response.data.Pin;
                this.hasThePassword = true;
                this.isLoading = false;
                resolve();
              } else {
                this.hasThePassword = false;
              }
              this.isLoading = false;
              reject();
            })
            .catch(error => {
              console.log("rerr:", error.response);
              this.displayError(error.response.data.StatusMessage);
              this.errormsg = error.response.data.StatusMessage;
              this.isLoading = false;
              this.hasThePassword = false;
              reject();
            });
        });
    },

    resetPin() {
      this.timer = 120;
      this.getPincode()
        .then(() => {
          this.showTimer();
        })
        .catch(error => {
          this.displayError(error.response.data.StatusMessage);
        });
    },

    showTimer() {
      this.timer = 120;
      this.$nextTick(() => {
        if (this.timer) {
          const interval = setInterval(
            function() {
              const delta = 1;
              this.timer -= delta;
              if (this.timer <= 0) {
                this.timer = 0;
                this.checkMobilStatus();
                clearInterval(interval);
              }
            }.bind(this),
            1000
          );
        }
      });
    },
    async checkMobilStatus() {
      this.isLoading = true;
      await this.getMobilStatus().then(() => {
        this.isLoading = false;
        if (this.mobilAppInstalled) {
          this.removeListener().then(() => {
            window.location.href = CLIENT_URL + "/?token=" + this.token;
           });
        }
      });
    },
    getMobilStatus() {
      this.isLoading = true;
      return new Promise((resolve, reject) => {
        API.get(API_MOBIL_STATUS, {
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        })
          .then(response => {
            if (response.data.StatusCode === 200) {
              this.mobilAppInstalled = true;
              this.$snotify.success(response.data.StatusMessage);
              resolve();
            } else {
              this.mobilAppInstalled = false;
              this.displayError(response.data.StatusMessage);
            }
            this.isLoading = false;
            reject();
          })
          .catch(error => {
            console.log("rerr:", error.response);
            this.displayError(error.response.data.StatusMessage);
            this.errormsg = error.response.data.StatusMessage;
            this.isLoading = false;
            this.hasThePassword = false;
            reject();
          });
      });
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    getParams(url) {
      var params = {};
      var parser = document.createElement("a");
      parser.href = url;
      var query = parser.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        params[pair[0]] = decodeURIComponent(pair[1]);
      }
      return params;
    },
    async getManagerStatus(email, code) {
      this.isLoading = true;
      const preparePost = { email: email };
      if (code) preparePost.code = code;
      const self = this;
      return new Promise((resolve, reject) => {
        API.post(API_CHECK_MANAGER, preparePost, {
          headers: {
            "content-type": "application/json"
          }
        })
          .then(response => { 
            if (response.data.StatusCode === 200) {
              resolve(response.data.DemoId);
              self.isLoading = false;
            } else {
              reject();
            }
          })
          .catch(error => {
            self.displayError(error.response.data.StatusMessage);
            self.isLoading = false;
            reject();
          });
        });
    },
    async getUserStatus(email, password) {
      this.isLoading = true;
      // const payload = new FormData();
      // payload.append("email", email);
      // payload.append("password", password);
      // payload.append("lang", "en");
      const preparePost = { email: email, password: password, lang: "en" };
      const self = this;
      return new Promise((resolve, reject) => {
        API.post(API_CHECK_USER, preparePost, {
          headers: {
            "content-type": "application/json"
          }
        })
          .then(response => { 
            if (response.data.StatusCode === 200) {
              resolve(response.data);
              self.isLoading = false;
            } else {
              reject();
            }
          })
          .catch(error => {
            self.displayError(error.response.data.StatusMessage);
            self.isLoading = false;
            reject();
          });
        });
    }
  }
};
</script>