import VueI18n from 'vue-i18n';
import Vue from 'vue';
import en from '@/lang/en.json';
import tr from '@/lang/tr.json';

export const DEFAULT_LANGUAGE = 'en';
export const FALLBACK_LANGUAGE = 'en';
export const SUPPORTED_LANGUAGES = ['en', 'tr'];

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: DEFAULT_LANGUAGE,
  fallbackLocale: FALLBACK_LANGUAGE,
  silentFallbackWarn: false,
  messages: { en, tr },
});