<template>
  <v-app>
    <vue-snotify/>
    <v-main>
      <keep-alive>
        <router-view />
      </keep-alive>
    </v-main>
  </v-app>
</template>
<script>
import {Translation} from './plugins/translation';

export default {
  name: 'App',
  data: () => ({
    //
  }),
  created() {
    const lang = navigator.language || navigator.userLanguage;
    Translation.changeLanguage(lang.split('-')[0]);
  }
};
</script>
<style lang="scss">
html {
  overflow-x: auto !important;
  overflow: auto !important;
}
.v-window__container {
  width: 100% !important;
  display: flex!important;
  justify-content: center!important;
  transition:1s cubic-bezier(0.25, 0.8, 0.5, 1)!important;
}
.theme--dark.v-btn.v-btn--disabled {
  color: #82b1ff !important;
}
.v-messages {
    min-height: fit-content!important;
}
.v-text-field__details {
    min-height: fit-content!important;
    margin-bottom: 0px!important;
}
@keyframes flashit {
  50% {
    opacity: 0.3;
  }
}
.v-list-item--active {
    animation: 0.25s ease 0s 2 normal none running flashit;
}
.theme--dark.v-btn.v-btn--disabled {
    color: rgba(102, 100, 100, 0.7) !important;
}

</style>