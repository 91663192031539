<template>
  <div class="signup-page" @mousedown="hideMsg = true">
    <div class="steps-info" v-if="showStepInfo">
      <div class="text-subtitle-2 font-weight-bold">
        {{ $t("steps_info_header", { step: currentStep, total: totalStep }) }}
      </div>
      <div class="text-subtitle-2 font-weight-normal ml-2">
        {{ stepsInfoTitle }}
      </div>
    </div>
    <div class="site-logo">
      <img
        class="logom"
        height="40"
        width="auto"
        src="@/assets/journey-logo-white.png"
      />
    </div>
    <v-form ref="Form" v-focusNextOnEnter lazy-validation>
      <v-window id="pencere" v-model="steps" touchless class="mywindow">
        <v-window-item :value="0" />
        <v-window-item :value="1">
          <v-card class="pa-5 signup-card flex-column">
            <div class="navbuttongroup">
              <v-btn
                :disabled="true"
                v-show="false"
                icon
                large
                text
                color="#2E58EB"
                @click="backStep"
              >
                <v-icon large> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn icon color="#2E58EB" text large v-show="redirectTo && steps < maxStep" @click="nextStep">
                <v-icon large> mdi-chevron-right </v-icon>
              </v-btn>
            </div>
            <v-card-text class="text-h5 font-weight-bold text-center">
              {{ $t("create_account") }}
            </v-card-text>
            <div class="ma-4 mt-8 panel-box">
              <v-text-field
                autocomplete="new-entry"
                autofocus
                :placeholder="$t('email_placeholder')"
                type="Email"
                :min="6"
                v-model="email"
                :rules="email_rules"
                single-line
                outlined
                validate-on-blur
                dense
                :error-count="1"
                color="#99999B"
                ref="loginEmail"
                class="entry-input mb-5"
                @input="emailHasAlreadyTaken = false"
                :class="{'error--text': emailHasAlreadyTaken}"
              />
              <v-text-field
                autocomplete="new-entry"
                single-line
                outlined
                dense
                color="#99999B"
                class="entry-input"
                :rules="password_rules"
                validate-on-blur
                :placeholder="$t('password_placeholder')"
                :append-icon="togglePassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="togglePassword ? 'text' : 'password'"
                :error-count="1"
                @input="emailHasAlreadyTaken = false"
                @click:append="togglePassword = !togglePassword"
                :min="4"
                v-model="password"
              />
              <div v-if="emailHasAlreadyTaken" class="email-taken">
                <span class="error--text">Email has already been taken.</span>
                <span class="pl-1"> Want to </span><a @click="redirectTo='sign_in';steps=3" class="d-flex justify-center pl-1"> Log in?</a>
              </div>
              <div class="d-flex mt-4">
                <v-btn
                  :disabled="!email || !password"
                  outlined
                  width="100%"
                  class="cont-button"
                  color="#2E58EB"
                  dark
                  :loading="isLoading"
                  @dblclick="noDouble()"
                  @click="checkUser()"
                >
                  {{ $t("continue") }}
                </v-btn>
              </div>
              <div class="notice" v-html="$t('notice')"></div>
              <a @click="redirectTo='sign_in';steps=3" class="d-flex justify-center mt-5">I have an account</a>
            </div>
          </v-card>
        </v-window-item>
        <v-window-item :value="2" />
        <v-window-item :value="redirectTo === 'sign_in' ? 3 : 33" v-show="redirectTo === 'sign_in'">
          <v-card class="pa-5 signup-card flex-column">
            <div class="navbuttongroup">
              <v-btn
                icon
                large
                text
                color="#2E58EB"
                @click="backStep"
              >
                <v-icon large> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn icon color="#2E58EB" text large v-show="redirectTo && steps < maxStep" @click="nextStep">
                <v-icon large> mdi-chevron-right </v-icon>
              </v-btn>
            </div>
            <v-card-text class="text-h5 font-weight-bold text-center">
              {{ $t("sign_in") }}
            </v-card-text>
            <div class="ma-4 mt-8 panel-box">
              <v-text-field
                autocomplete="new-entry"
                autofocus
                :placeholder="$t('email_placeholder_')"
                type="Email"
                :min="6"
                v-model="email"
                :rules="email_rules"
                single-line
                outlined
                dense
                @input="emailOrPasswordWrong = null"
                :error-count="1"
                color="#99999B"
                class="entry-input mb-5"
              />
              <v-text-field
                autocomplete="new-entry"
                single-line
                outlined
                dense
                color="#99999B"
                class="entry-input"
                :rules="password_rules"
                :placeholder="$t('password_placeholder_')"
                :append-icon="togglePassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="togglePassword ? 'text' : 'password'"
                :error-count="1"
                @click:append="togglePassword = !togglePassword"
                :min="4"
                @input="emailOrPasswordWrong = null"
                v-model="password"
              />
              <div v-if="emailOrPasswordWrong" class="email-taken">
                <span class="error--text">{{  emailOrPasswordWrong }}</span>
              </div>
              <div class="d-flex mt-5">
                <v-btn
                  :disabled="!email || !password"
                  outlined
                  width="100%"
                  class="cont-button"
                  color="#2E58EB"
                  dark
                  :loading="isLoading"
                  @dblclick="noDouble()"
                  @click="checkUser(true)"
                >
                  {{ $t("continue") }}
                </v-btn>
              </div>
                <span class="d-flex justify-center mt-5">Don't you have an account? <a @click="steps=1" class="pl-1">Join.</a></span>
            </div>
          </v-card>
        </v-window-item>
        <v-window-item :value="redirectTo === 'profile' ? 3 :33" v-show="redirectTo === 'profile'">
          <v-card class="pa-5 signup-card flex-column">
            <div class="navbuttongroup">
              <v-btn icon large text color="#2E58EB" @click="backStep">
                <v-icon large> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn icon color="#2E58EB" text large v-show="redirectTo && steps < maxStep" @click="nextStep">
                <v-icon large> mdi-chevron-right </v-icon>
              </v-btn>
            </div>
            <v-card-text class="text-h5 font-weight-bold text-center">
              {{ $t("tellus_aboutself") }}
            </v-card-text>
            <div class="ma-4 mt-8 panel-box">
              <v-text-field
                autocomplete="new-entry"
                autofocus
                :placeholder="$t('firstname_placeholder')"
                type="text"
                :min="6"
                v-model="first_name"
                :rules="is_required"
                single-line
                outlined
                dense
                color="#99999B"
                class="entry-input mb-5"
              />
              <v-text-field
                autocomplete="new-entry"
                single-line
                outlined
                dense
                color="#99999B"
                class="entry-input"
                :rules="is_required"
                :placeholder="$t('lastname_placeholder')"
                type="text"
                :min="4"
                v-model="last_name"
              />
              <div class="d-flex">
                <v-btn
                  :disabled="!first_name || !last_name"
                  outlined
                  width="100%"
                  class="cont-button"
                  color="#2E58EB"
                  dark
                  @dblclick="noDouble()"
                  @click="nextStep"
                >
                  {{ $t("continue") }}
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-window-item>
        <template v-if="position === 'Manager'">
          <v-window-item :value="4">
            <v-card class="pa-5 signup-card flex-column">
              <div class="navbuttongroup">
                <v-btn icon large text color="#2E58EB" @click="backStep">
                  <v-icon large> mdi-chevron-left </v-icon>
                </v-btn>
                <v-btn icon color="#2E58EB" text large v-show="redirectTo && steps < maxStep" @click="nextStep">
                  <v-icon large> mdi-chevron-right </v-icon>
                </v-btn>
              </div>
              <v-card-text class="text-h6 font-weight-bold text-center">
                {{ $t("whom_to_develop") }}
              </v-card-text>
              <div class="ma-4 mt-8 panel-box member-entry-name" v-if="steps > 2">
                <v-container class="pa-0">
                <template v-for="(member, i) in members">
                  <v-row
                    align="center"
                    :key="`${i}row`"
                    no-gutters
                  >
                  <v-col cols="7">
                  <v-text-field
                    :key="i"
                    :autofocus="i === 0 ? true:false"
                    autocomplete="new-entry"
                    :placeholder="$t('email_placeholder')"
                    type="Email"
                    :min="6"
                    v-model="members[i].email"
                    :rules="email_rules"
                    :error-count="1"
                    validate-on-blur
                    single-line
                    outlined
                    dense
                    color="#99999B"
                    class="entry-input mb-5 eposta-input"
                  />
                  </v-col>
                  <v-col cols="5">
                  <v-text-field
                    :key="i + 'name'"
                    autocomplete="new-entry-name"
                    :placeholder="$t('name_placeholder')"
                    type="text"
                    :min="2"
                    v-model="members[i].first_name"
                    :rules="is_required"
                    :error-count="1"
                    single-line
                    validate-on-blur
                    outlined
                    dense
                    color="#99999B"
                    @keydown="checkNeedEmptyField"
                    class="member-name entry-input mb-5 isim-input"
                  />
                  </v-col>
                  </v-row>
                </template>
                </v-container>
                <div class="d-flex mb-4">
                  <v-btn
                    outlined
                    :disabled="isBtnDisabled || isLoading"
                    width="100%"
                    :class="isRetry ? 'retry-button' : 'cont-button'"
                    color="#2E58EB"
                    dark
                    :loading="isLoading"
                    @dblclick="noDouble()"
                    @click="postSignup"
                  >
                    {{ isRetry ? $t("retry") : $t("continue") }}
                  </v-btn>
                </div>
                
                <v-card-text
                  color="#6C6C6C"
                  class="text-center pa-0"
                >
                  {{ $t("notice_manager") }}
                </v-card-text>
              </div>
            </v-card>
          </v-window-item>
          <v-window-item :value="5">
            <v-card class="pa-5 signup-card flex-column">
              <div class="navbuttongroup">
                <v-btn icon large text color="#2E58EB" :disabled="true" @click="backStep">
                  <v-icon large> mdi-chevron-left </v-icon>
                </v-btn>
                <v-btn icon color="#2E58EB" text large @click="nextStep">
                  <v-icon large> mdi-chevron-right </v-icon>
                </v-btn>
              </div>
              <v-card-text class="text-subtitle-1 font-weight-bold text-center">
                {{ $t("share_the_link", {names: namesTobeShare }) }}
              </v-card-text>
              <div class="ma-4 mt-8 panel-box">
                <div class="panel-link-div">
                  <div class="panel-share-text">
                    {{ invite_link }}
                  </div>
                  <v-btn
                    depressed
                    dark
                    :color="isCopying ? '#2EB08E' : '#2E58EB'"
                    class="shareButton"
                    :ripple="false"
                    @dblclick="noDouble()"
                    @click.stop="shareClick($event)"
                  >
                  <template v-if="isCopying">
                    {{ $t("copied") }} <v-icon>mdi-check</v-icon>
                  </template>
                  <template v-else>
                    {{ $t("copy") }}
                  </template>
                  </v-btn>
                </div>
                <div class="d-flex mb-4">
                  <v-btn
                    outlined
                    width="100%"
                    class="cont-button"
                    color="#2E58EB"
                    dark
                    @dblclick="noDouble()"
                    @click="nextStep"
                  >
                    {{ $t("continue") }}
                  </v-btn>
                </div>
              </div>
            </v-card>
          </v-window-item>
          <!-- <v-window-item :value="5">
            <v-card class="pa-5 signup-card">
              <div class="navbuttongroup">
                <v-btn icon large text color="#2E58EB" @click="backStep">
                  <v-icon large> mdi-chevron-left </v-icon>
                </v-btn>
                <v-btn icon color="#2E58EB" text large @click="nextStep">
                  <v-icon large> mdi-chevron-right </v-icon>
                </v-btn>
              </div>
              <div class="navbuttongroup">
                <v-btn icon large text color="#2E58EB" @click="backStep">
                  <v-icon large> mdi-chevron-left </v-icon>
                </v-btn>
                <v-btn icon color="#2E58EB" text large @click="nextStep">
                  <v-icon large> mdi-chevron-right </v-icon>
                </v-btn>
              </div>
              <div class="container">
                <div class="row d-flex align-items-center">
                  <div class="col-12 col-sm-6 align-self-center">
                    <img
                      class="mobilapp"
                      width="100%"
                      src="@/assets/mobilapp.png"
                    />
                  </div>
                  <div
                    class="col-12 col-sm-6 d-flex flex-column align-self-center pa-4"
                  >
                    <div class="steps">
                      {{ $t("signup_steps") }}
                    </div>
                    <div class="pin-numbers">
                      <span class="pinbox">{{ pinpassword[0] }}</span>
                      <span class="pinbox">{{ pinpassword[1] }}</span>
                      <span class="dash">{{ "-" }}</span>
                      <span class="pinbox">{{ pinpassword[2] }}</span>
                      <span class="pinbox">{{ pinpassword[3] }}</span>
                    </div>
                    <div class="timerbox">
                      <div class="timer-message">
                        {{
                          $t("time_left") +
                          minutes.toString().padStart(2, "0") +
                          " : " +
                          seconds.toString().padStart(2, "0")
                        }}
                      </div>
                      <v-btn
                        v-if="!timer"
                        color="#254FFB"
                        outlined
                        x-small
                        @click="resetPin"
                      >
                        <v-icon>mdi-refresh</v-icon>
                        {{ $t("regenerate_pin") }}
                      </v-btn>
                    </div>
                    <div class="errorbox" v-if="errormsg">
                      <div class="v-messages__message">
                        {{ errormsg }}
                      </div>
                    </div>
                    <div class="d-flex justify-center">
                      <a
                        href="https://itunes.apple.com/tr/app/journey-by-vibons/id1412829495?mt=8"
                        target="_blank"
                      >
                        <img
                          class="appicon mr-2"
                          height="40"
                          width="auto"
                          src="@/assets/iosapp.png"
                        />
                      </a>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.vibons.journeyapp"
                        target="_blank"
                      >
                        <img
                          class="appicon"
                          height="40"
                          width="auto"
                          src="@/assets/androidapp.png"
                        />
                      </a>
                    </div>
                    <div class="d-flex justify-space-around ma-4">
                      <v-btn
                        outlined
                        width="100%"
                        class="mt-8 cont-button"
                        color="#2E58EB"
                        dark
                        :loading="isLoading"
                        @dblclick="noDouble()"
                        @click="checkMobilStatus"
                      >
                        {{ $t("continue") }}
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </v-card>
          </v-window-item> -->
          <v-window-item :value="6" eager>
          <v-card class="pa-5 signup-card flex-column">
            <div class="navbuttongroup">
              <v-btn icon large text color="#2E58EB" @click="backStep">
                <v-icon large> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn icon color="#2E58EB" text large @click="nextStep">
                <v-icon large> mdi-chevron-right </v-icon>
              </v-btn>
            </div>
            <v-card-text
              class="text-subtitle-1 font-weight-black text-center whitebr"
            >
              {{ $t("video_skip") }}
            </v-card-text>
            <div class="ma-4 mt-8 video-panel-box">
              <video
                id="signupVideo"
                ref="signupVideo"
                preload="auto"
                @playing="updatePaused"
                @pause="updatePaused"
                @mouseover="showControl = true"
                @mouseleave="showControl = false"
                @ended="gotoHomepage"
                controls
                playsinline
                webkit-playsinline
                volume="0.5"
                oncontextmenu="return false;"
                controlsList="nodownload"
              >
                <source :src="videoUrl" type="video/mp4" />
                {{ $t("not_support_html5") }}
              </video>
              <div class="d-flex justify-center">
                <v-btn
                  :disabled="!email || !password"
                  outlined
                  autofocus
                  width="60%"
                  class="cont-button"
                  color="#2E58EB"
                  dark
                  @dblclick="noDouble()"
                  @click="gotoHomepage"
                >
                  {{ $t("skip") }}
                </v-btn>
              </div>
            </div>
          </v-card>
          </v-window-item>
          <!-- <v-window-item :value="6">
            <v-card class="pa-5 signup-card">
              <div class="navbuttongroup">
                <v-btn icon large text color="#2E58EB" @click="backStep">
                  <v-icon large> mdi-chevron-left </v-icon>
                </v-btn>
                <v-btn
                  icon
                  color="#2E58EB"
                  text
                  large
                  :disabled="true"
                  @click="nextStep"
                >
                  <v-icon large> mdi-chevron-right </v-icon>
                </v-btn>
              </div>
              <div class="container tebrik">
                <div
                  class="row d-flex align-items-center tebrikrow justify-center"
                >
                  <div
                    class="col-12 d-flex flex-column align-items-center justify-center"
                  >
                    <img
                      class="checkicon"
                      height="63"
                      width="63"
                      src="@/assets/greencheck.png"
                    />
                    <div class="congralute">
                      {{ $t("congralute") }}
                    </div>

                    <div class="d-flex">
                      <v-btn
                        outlined
                        width="100%"
                        class="cont-button"
                        color="#2E58EB"
                        dark
                        :loading="isLoading"
                        @dblclick="noDouble()"
                        @click="gotoHomepage"
                      >
                        {{ $t("goto_homepage") }}
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </v-card>
          </v-window-item> -->
        </template>
        <template v-if="position === 'HR'">
          <v-window-item :value="4">
            <v-card class="pa-5 signup-card flex-column">
              <div class="navbuttongroup">
                <v-btn icon large text color="#2E58EB" @click="backStep">
                  <v-icon large> mdi-chevron-left </v-icon>
                </v-btn>
                <v-btn icon color="#2E58EB" text large @click="nextStep">
                  <v-icon large> mdi-chevron-right </v-icon>
                </v-btn>
              </div>
              <v-card-text class="text-h6 font-weight-bold text-center">
                {{ $t("invite_managers") }}
              </v-card-text>
              <div class="ma-4 mt-8 panel-box member-entry-name" v-if="steps > 3">
                <v-container class="pa-0">
                <template v-for="(member, i) in members">
                  <v-row
                    align="center"
                    :key="`${i}row`"
                    no-gutters
                  >
                  <v-col cols="7">
                  <v-text-field
                    :key="i"
                    autocomplete="new-entry"
                    :autofocus="i === 0 ? true:false"
                    :placeholder="$t('email_placeholder')"
                    type="Email"
                    :min="6"
                    v-model="members[i].email"
                    :rules="email_rules"
                    :error-count="1"
                    single-line
                    outlined
                    dense
                    color="#99999B"
                    class="entry-input mb-5 eposta-input"
                  />
                  </v-col>
                  <v-col cols="5">
                  <v-text-field
                    :key="i + 'name'"
                    autocomplete="new-entry-name"
                    :placeholder="$t('name_placeholder')"
                    type="text"
                    :min="2"
                    v-model="members[i].first_name"
                    :rules="is_required"
                    :error-count="1"
                    single-line
                    outlined
                    dense
                    color="#99999B"
                    @keydown="checkNeedEmptyField"
                    class="member-name entry-input mb-5 isim-input"
                  />
                  </v-col>
                  </v-row>
                </template>
                </v-container>
                <div class="d-flex mb-4">
                  <v-btn
                    outlined
                    :disabled="!members[0] || isLoading"
                    width="100%"
                    :class="isRetry ? 'retry-button' : 'cont-button'"
                    color="#2E58EB"
                    dark
                    :loading="isLoading"
                    @dblclick="noDouble()"
                    @click="postSignup"
                  >
                    {{ isRetry ? $t("retry") : $t("continue") }}
                  </v-btn>
                </div>
                <v-card-text
                  color="#6C6C6C"
                  class="text-subtitle-2 font-weight-thin text-center pa-0"
                >
                  {{ $t("notice_hr") }}
                </v-card-text>
              </div>
            </v-card>
          </v-window-item>
          <v-window-item :value="5">
            <v-card class="pa-5 signup-card flex-column">
              <div class="navbuttongroup">
                <v-btn icon large text color="#2E58EB" @click="backStep">
                  <v-icon large> mdi-chevron-left </v-icon>
                </v-btn>
                <v-btn icon color="#2E58EB" text large @click="nextStep">
                  <v-icon large> mdi-chevron-right </v-icon>
                </v-btn>
              </div>
              <v-card-text class="text-subtitle-1 font-weight-bold text-center">
                {{ $t("share_with_manager") }}
              </v-card-text>
              <div class="ma-4 mt-8 panel-box">
                <div class="panel-link-div">
                  <div class="panel-share-text">
                    {{ invite_link }}
                  </div>
                  <v-btn
                    depressed
                    dark
                    :color="isCopying ? '#2EB08E' : '#2E58EB'"
                    class="shareButton"
                    :ripple="false"
                    @dblclick="noDouble()"
                    @click.stop="shareClick($event)"
                  >
                    {{ $t("copy") }}
                  </v-btn>
                </div>
                <div class="d-flex mb-4">
                  <v-btn
                    outlined
                    width="100%"
                    class="cont-button"
                    color="#2E58EB"
                    dark
                    @dblclick="noDouble()"
                    @click="nextStep"
                  >
                    {{ $t("continue") }}
                  </v-btn>
                </div>
              </div>
            </v-card>
          </v-window-item>
          <v-window-item :value="6">
            <v-card class="pa-5 signup-card">
              <div class="navbuttongroup">
                <v-btn icon large text color="#2E58EB" @click="backStep">
                  <v-icon large> mdi-chevron-left </v-icon>
                </v-btn>
                <v-btn
                  icon
                  color="#2E58EB"
                  text
                  :disabled="true"
                  @click="nextStep"
                >
                  <v-icon large> mdi-chevron-right </v-icon>
                </v-btn>
              </div>
              <div class="container tebrik">
                <div
                  class="row d-flex align-items-center tebrikrow justify-center"
                >
                  <div
                    class="col-12 d-flex flex-column align-items-center justify-center"
                  >
                    <img
                      class="checkicon"
                      height="63"
                      width="63"
                      src="@/assets/greencheck.png"
                    />
                    <div class="congralute">
                      {{ $t("congralute") }}
                    </div>

                    <div class="d-flex">
                      <v-btn
                        outlined
                        width="100%"
                        class="cont-button"
                        color="#2E58EB"
                        dark
                        @dblclick="noDouble()"
                        :loading="isLoading"
                        @click="gotoPlatform"
                      >
                        {{ $t("go_to_platform") }}
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </v-card>
          </v-window-item>
        </template>
      </v-window>
    </v-form>
  </div>
</template>

<script>
import Helper from "./../plugins/helper.vue";
import { API, API_SIGNUP, SMB_VIDEO_URL, CLIENT_URL } from "./../plugins/config";

export default {
  name: "SignUp",
  mixins: [Helper],
  data: () => ({
    email: null,
    password: null,
    videoUrl: SMB_VIDEO_URL,
    videoElement: null,
    paused: null,
    emailOrPasswordWrong: null,
    showControl: false,
    position: "Manager",
    first_name: null,
    last_name: null,
    members: [{email:'', first_name:''}, {email:'', first_name:''}, {email:'', first_name:''}],
    isCopying: false,
    invite_link: "",
    showStepInfo: false,
    stepsInfoTitle: "Profile",
    togglePassword: false,
    redirectTo: null,
    emailHasAlreadyTaken: false,
    maxStep: 0,
  }),
  watch: {
    steps(newValue, oldValue) {
      console.log("this.step:", newValue);
      if (newValue > oldValue && newValue > this.maxStep) {
        this.maxStep = newValue;
      }
      this.$refs.Form.resetValidation();
      if (newValue === 6) {
        this.$refs.signupVideo.play();
      }

      if (newValue > 0) {
        this.showStepInfo = true;
        if (this.position === "Manager") {
          this.totalStep = 4; // 5
          switch (this.steps) {
            case 1:
            case 2:
              this.showStepInfo = false;
               break;
            case 3: //3
              this.stepsInfoTitle = "Profile";
              this.currentStep = 1;
              break;
            case 4: // 4
              this.stepsInfoTitle = "Create your team";
              this.currentStep = 2;
              break;
            case 5: // 5
              this.stepsInfoTitle = "Share invite link";
              this.currentStep = 3;
              break;
            case 6: // 6
              this.stepsInfoTitle = "See how Journey works";
              this.currentStep = 4;
              // this.getPincode();
              // this.showTimer();
              break;
            case 61:
              this.stepsInfoTitle = "";
              this.currentStep = 5;
              break;
            default:
              break;
          }
        } else {
          this.totalStep = 4;
          switch (this.steps) {
            case 3:
              this.stepsInfoTitle = "Profile";
              this.currentStep = 1;
              break;
            case 4:
              this.stepsInfoTitle = "Add managers";
              this.currentStep = 2;
              break;
            case 5:
              this.stepsInfoTitle = "Share invite link";
              this.currentStep = 3;
              break;
            case 6:
              this.stepsInfoTitle = " ";
              this.currentStep = 4;
              break;
            default:
              break;
          }
        }
      }
    }
  },
  computed: {
    namesTobeShare() {
      if (this.members.length > 0) {
        return this.members.map((member) => member.first_name.split(" ")[0]).join(", ");
      }
      return ''; 
    },
    isBtnDisabled() {
      let members = [...this.members];
      members = members.filter(str => str.email !== '' && str.first_name !== '');
      return (members.length < 1);
    },
  },
  created() {
    const { urlCode } = this.$route.params;
    if (urlCode) {
      this.urlCode = urlCode;
    }
  },
  mounted() {
    const { lang } = this.getParams(window.location.href);
    if (lang) this.$i18n.locale = lang;

    setTimeout(() => {
      this.steps = 1;
    }, 750);
  },
  directives: {
          focusNextOnEnter: {
              inserted(el, binding, vnode) {
                  el.addEventListener('keydown', (ev) => {
                      let index = [...vnode.elm.elements].indexOf(ev.target);
                      let inc = 1;
                      while(vnode.elm[index + inc] && vnode.elm[index + inc].tagName === 'FIELDSET'){
                        inc++;
                      }
                      if (ev.key === 'Enter' && index < vnode.elm.length - 1) {
                        vnode.elm[index + inc].focus();
                      }
                  });
              },
          },
      },
  methods: {
    async checkValidation() {
      const res = this.$refs.Form.validate();
      if (res) {
        this.hideMsg = true;
        return true;
      }
      this.hideMsg = false;
      return false;
    },

    checkManagerOrCont() {
      if (this.urlCode) {
        this.getManagerStatus(this.email, this.urlCode).then((response) => {
          this.demoId = response;
          this.position = 'Manager';
          this.steps = 3; //3
          this.isLoading = false;
        });
      } else {
        this.nextStep();
      }
    },

    async checkUser(alreadyChecked = false) {
      const isValid = await this.checkValidation();
      if (isValid) {
        this.getUserStatus(this.email, this.password).then((response) => {
          console.log('sonuc:', alreadyChecked, response);
          if (response.StatusCode === 200) {
            if (!alreadyChecked) {
              this.redirectTo = response.redirect_to;
              if (response.redirect_to === null) {
                this.steps = 1;
                this.emailHasAlreadyTaken = true;
                this.$refs.loginEmail.focus();
              } else if (response.redirect_to === 'profile') {
                this.steps = 3;
              } else if (response.redirect_to === 'step_2') {
                this.first_name = response.FirstName;
                this.last_name = response.LastName;
                this.steps = 4;
              }
            } else {
              this.redirectTo = response.redirect_to;
              if (response.redirect_to === null) {
                this.redirectTo = 'sign_in';
                this.steps = 3;
                this.emailOrPasswordWrong = 'Email or password incorrect!';
              } else if (response.redirect_to === 'step_2') {
                this.first_name = response.FirstName;
                this.last_name = response.LastName;
                this.steps = 4;
              } else {
                this.steps += 1;
              }
            }
          }
        });
      }
    },
    updatePaused(event) {
      this.videoElement = event.target;
      this.paused = event.target.paused;
    },

    skipVideo() {
      if (this.videoElement) {
        this.videoElement.pause();
      }
      this.steps = 5;
    },

    exitVideo() {
      setTimeout(() => {
        this.steps = 6;
      }, 3000);
    },

    async nextStep() {
      await this.sleep(500);
      if (this.steps === 1 && !this.email && !this.password && !this.redirectTo) {
        return;
      } else if (this.steps === 2 && !this.position && !this.redirectTo) {
        return;
      } else if (this.steps === 3 && !this.position && !this.redirectTo) {
        return;
      } else if (this.steps === 1 && this.redirectTo === 'sign_in') { 
        this.steps += 2;
      } else if (this.steps === 1 && this.redirectTo === 'step_2') { 
        this.steps += 3;
      } else {
        const isValid = await this.checkValidation();
        if (isValid) {
          this.steps += 1;
        }
      }
    },

    async backStep() {
      await this.sleep(500);
      if (this.steps === 1) {
        return;
      } else if (this.steps === 3) {
        this.steps -= 2;
      } else if (this.steps === 4 && this.redirectTo === 'step_2') {
        this.steps -= 3;
      } else if (this.steps === 6) {
        return;
      } else {
        this.steps -= 1;
      }
    },

    postSignup() {
      this.members = this.members.filter(str => str.email !== '' && str.first_name !== '');
      if (this.members.length) {
        this.$nextTick(() => this.nowPostSignup());
      } else {
        this.members.push({email:'', first_name:''});
      }
    },
  
    async nowPostSignup() {
      this.isLoading = true;
      let isValid = false;
      isValid = await this.checkValidation();
      if (!isValid) {
        console.log("not valid yet:", this.members);
        this.isLoading = false;
        return;
      }
      const preparePost = {};
      preparePost.new_user = { lang: "en" };
      preparePost.new_user.first_name = this.first_name;
      preparePost.new_user.last_name = this.last_name;
      preparePost.new_user.email = this.email;
      preparePost.new_user.password = this.password;
      preparePost.new_user.position = this.position;
      preparePost.members = this.members;
      if (this.demoId) {
        preparePost.demo_id = this.demoId;
      }

      const self = this;
      await API.post(API_SIGNUP, preparePost, {
        headers: {
          "content-type": "application/json"
        }
      })
        .then(response => {
          self.$snotify.success(response.data.StatusMessage);
          self.invite_link = response.data.Url;
          self.code = response.data.Code;
          self.token = response.data.Token;
          self.isLoading = false;
          self.isRetry = false;
          self.steps += 1;
        })
        .catch(error => {
          console.log('error:', error.response);
          if (error.response.data && error.response.data.errors && error.response.data.errors.email && error.response.data.errors.email[0]) {
            self.displayError(error.response.data.errors.email[0]);
          } else {
            self.displayError(error.response);
          }
          self.isLoading = false;
          self.isRetry = true;
          self.members.push({email:'', first_name:''})
        });
    },

    gotoHomepage() {
      if (this.videoElement) {
        this.videoElement.pause();
      }
      this.removeListener().then(()=> {
        window.location.href = CLIENT_URL + "/?token=" + this.token;
       });
    },

    gotoPlatform() {
      this.removeListener().then(()=> {
        window.location.href = "https://admin.journeyapp.com";
      });
    },

    async shareClick(event) {
      const self = this;
      self.copyText(self.invite_link);
      const $event = event;
      if ($event && $event.target) {
        $event.target.disabled = true;
        this.isCopying = true;
        await setTimeout(() => {
          $event.target.disabled = false;
          this.isCopying = false;
        }, 2000);
        document.activeElement.blur();
      }
    },

    fallbackCopyText(text) {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand("copy");
        const msg = successful ? "successful" : "unsuccessful";
        console.log(`Fallback: Copying text command was ${msg}`);
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }
    },

    noDouble() {
      console.log("Empty double click handler..");
    },

    copyText(text) {
      if (!navigator.clipboard) {
        this.fallbackCopyText(text);
        return;
      }
      navigator.clipboard.writeText(text).then(
        () => {
          console.log("Async: Copying to clipboard was successful!");
        },
        err => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },

    checkNeedEmptyField() {
      const members = this.members.filter(str => str.email !=='' && str.first_name !== '');
      if (this.position === "Manager") {

          if (
            members.length > 2 &&
            members.length === this.members.length && this.members.length < 6
          ) {
            this.members.push({email:'', first_name:''});
          }
          if (this.members.length < 3) {
            this.members.push({email:'', first_name:''});
          } 
        } 

        if (this.position === "HR") {
          if (members.length < 3 && members.length === this.members.length && this.members.length < 3) {
            this.members = this.members.filter(str => str.email !=='' && str.first_name !== '');
            this.members.push({email:'', first_name:''});
          }
        }
    }
  }
};
</script>
<style lang="scss" scoped>
.signup-page {
  width: 100%;
  height: 100%;
  padding: 0 !important;
  margin: 0 !important;
  color: #363636;
  overflow: hidden;
  position: absolute !important;
  box-shadow: none !important;
  min-height: 100% !important;
  min-width: none !important;
  // background-color: #e6f2fe;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center bottom;
  // background-image: url("~@/assets/login_bg.png");
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .v-btn {
    text-transform: none;
  }
  .site-logo {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100px;
  }
  .mywindow {
    width: 100vw;
    min-height: 50vh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .v-window-item {
    margin: 10px;
  }
  .signup-card {
    width: 50vw;
    max-width: 630px;
    height: 100%;
    min-height: 430px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff85;
    backdrop-filter: blur(6px);
    box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.25);
    border-radius: 13px;
    overflow:hidden;
    .row {
      min-height: 360px;
    }
  }
  .panel-box {
    width: 70%;
  }
  .steps {
    white-space: pre-line;
    font-family: Karla;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #363636;
  }
  .dash {
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 35px;
    color: #000000;
    opacity: 0.3;
  }
  .pin-numbers {
    display: flex;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 35px;
    color: #000000;
    margin: 35px auto;
    align-items: center;
    .pinbox {
      background: #ffffff;
      border: 0.7px solid #d3d4d5;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 7px;
      margin: 5px;
      padding: 5px;
      font-style: normal;
      font-weight: normal;
      font-size: 30px;
      line-height: 35px;
      color: #000000;
    }
  }
  .checkicon {
    margin: 0 auto;
  }
  .congralute {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #000000;
    margin: 20px auto;
  }
  .errorbox {
    margin: -15px auto;
    margin-bottom: 10px;
  }
  .cont-button {
    text-transform: none !important;
    background: #f6f6f6;
    margin-top: 50px;
  }
  .retry-button {
    color: #ff5252 !important;
  }

  .entry-input {
    margin-bottom: -15px;
    .v-input__control {
      .v-input__slot {
        width: 90% !important;
        min-width: 320px;
        margin: 0 auto;
      }
      .v-text-field__details {
        width: 90% !important;
        min-width: 320px;
        margin: 0 auto;
      }
    }
  }
  .panel-link-div {
    background: #ffffff;
    border: 1px solid #2e58eb;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .panel-share-text {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      margin-left: 10px;
      line-height: 14px;
      min-width: 200px;
      text-align: center;
      color: #2e58eb;
    }
    .shareButton {
      background: #2e58eb;
      border: 1px solid #2e58eb;
      box-sizing: border-box;
      width: 100px;
      border-radius: unset;
    }
  }
  .tebrik {
    width: 100%;
  }
  .tebrikrow {
    width: 50%;
    margin: 0 auto;
  }
  .steps-info {
    display: flex;
    position: absolute;
    top: 10%;
    left: 20%;
    z-index: 100;
    font-family: Karla;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
  }
}

@media screen and (max-width: 720px) {
  .signup-page,
  .mywindow {
    height: 100% !important;
    max-height: none !important;
    align-items: flex-start;
    overflow: auto;
  }
  .site-logo {
    margin-top: 20px;
  }
  .signup-card {
    width: 90vw !important;
    height: 100% !important;
    max-height: none !important;
    overflow: auto;
  }
  .mobilapp {
    max-height: 250px;
    width: 100%;
    object-fit: contain;
  }
}

.v-list-item--link:before {
  background-color: transparent;
}
.timerbox {
  margin: -15px auto;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .timer-message {
    color: #ff5252 !important;
  }
}
.navbuttongroup {
  position: absolute;
  top: 15px;
  left: 15px;
}
.leftme {
  position: absolute !important;
  right: 2px;
}
.theme--dark.v-btn.v-btn--disabled {
  color: rgba(102, 100, 100, 0.7) !important;
}
.member-entry-name .container {
  .row {
    margin:0!important;
    min-height: 24px!important;
    .member-name {
      &::before{
        content: ' \2015';
        color:#2e57eb;
        line-height: 40px;
      }
    }
  }
}
.notice {
  font-size: 12px;
  color: rgba(102, 100, 100, 0.7) !important;
  margin-top: 20px;
  text-align: center;
}
video {
  width: 100%;
  z-index: 10;
  position: relative;
}
.whitebr {
  white-space: pre-line;
}
.signup-page .cont-button {
  margin-top: 20px;
}
.email-taken {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  font-size: 16px;
  color: rgba(102, 100, 100, 0.7) !important;
  text-align: center;
  line-height:1;
  span {
    display: inline-flex;
    line-height:1;
    a { 
      display: inline-flex;
      line-height:1;
    }
  }
}
</style>