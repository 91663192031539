import axios from "axios";

export const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export const CLIENT_URL = process.env.VUE_APP_API_CLIENT_URL;
export const API_GET_PIN_CODE = `${BASE_URL}/endevdemo/pin`;
export const API_SIGNUP = `${BASE_URL}/endevdemo/signup`;
export const API_ENTERPRISE_SIGNUP = `${BASE_URL}/enterprise/signup`;
export const API_ENTERPRISE_START= `${BASE_URL}/enterprise/ed/start`;
export const ENTERPRISE_VIDEO_URL = 'https://s3-spaced-learning-prod.s3.amazonaws.com/webapp/ed_assets/enterprise.mp4';
export const SMB_VIDEO_URL = 'https://s3-spaced-learning-prod.s3.amazonaws.com/webapp/ed_assets/smb.mp4';
export const API_MOBIL_STATUS = `${BASE_URL}/user/device/login/status`;
export const API_CHECK_MANAGER = `${BASE_URL}/endevdemo/check/manager`;
export const API_CHECK_USER = `${BASE_URL}/endevdemo/check/user`;


export const API = axios.create({
  baseURL: BASE_URL,
  timeout: 60000 // 1 min
});