import Vue from 'vue';
import VueRouter from 'vue-router';
import SignUpPage from './../components/SignUpPage.vue';
import EnterpriseSignUpPage from './../components/EnterpriseSignUpPage.vue';
import { BASE_URL } from './config';

Vue.use(VueRouter);

const routes = [
    {
      path: '/',
      name: 'signup',
      component: SignUpPage,
    },
    {
      path: '/code/:urlCode',
      name: 'codesignup',
      component: SignUpPage,
    },
    {
      path: '/enterprise',
      name: 'enterprise',
      component: EnterpriseSignUpPage,
    },
    {
        path: '*',
        redirect: '/',
    },
];

const router = new VueRouter({
    mode: 'history',
    base: BASE_URL,
    routes,
  });

  export default router;