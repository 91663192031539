<template>
  <div class="signup-page" @mousedown="hideMsg = true">
    <div class="steps-info" v-if="showStepInfo">
      <div class="text-subtitle-2 font-weight-bold">
        {{ $t("steps_info_header", { step: currentStep, total: totalStep }) }}
      </div>
      <div class="text-subtitle-2 font-weight-normal ml-2">
        {{ stepsInfoTitle }}
      </div>
    </div>
    <div class="site-logo">
      <img
        class="logom"
        height="40"
        width="auto"
        src="@/assets/journey-logo-white.png"
      />
    </div>
    <v-form ref="Form" lazy-validation>
      <v-window id="pencere" v-model="steps" touchless class="mywindow">
        <v-window-item :value="0" />
        <v-window-item :value="1">
          <v-card class="pa-5 signup-card flex-column">
            <div class="navbuttongroup">
              <v-btn
                :disabled="true"
                icon
                large
                text
                color="#2E58EB"
                @click="backStep"
              >
                <v-icon large> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn icon color="#2E58EB" text large @click="nextStep">
                <v-icon large> mdi-chevron-right </v-icon>
              </v-btn>
            </div>
            <v-card-text class="text-h5 font-weight-bold text-center">
              {{ $t("sign_up") }}
            </v-card-text>
            <div class="ma-4 mt-8 panel-box">
              <v-text-field
                autocomplete="new-entry"
                :placeholder="$t('email_placeholder')"
                type="Email"
                :min="6"
                v-model="email"
                :rules="email_rules"
                :hide-details="hideMsg"
                single-line
                outlined
                dense
                :error-count="3"
                color="#99999B"
                class="entry-input mb-5"
              />
              <v-text-field
                autocomplete="new-entry"
                single-line
                outlined
                dense
                color="#99999B"
                class="entry-input"
                :hide-details="hideMsg"
                :rules="password_rules"
                :append-icon="togglePassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="togglePassword ? 'text' : 'password'"
                @click:append="togglePassword = !togglePassword"
                :placeholder="$t('password_placeholder')"
                :error-count="5"
                :min="4"
                v-model="password"
              />
              <div class="d-flex">
                <v-btn
                  :disabled="!email || !password"
                  outlined
                  autofocus
                  width="100%"
                  class="cont-button"
                  color="#2E58EB"
                  dark
                  @dblclick="noDouble()"
                  @click="signup"
                  :loading="isLoading"
                >
                  {{ $t("continue") }}
                </v-btn>
              </div>
              <div class="notice" v-html="$t('notice')"></div>
            </div>
          </v-card>
        </v-window-item>
        <v-window-item :value="2" eager>
          <v-card class="pa-5 signup-card flex-column">
            <div class="navbuttongroup">
              <v-btn icon large text color="#2E58EB" @click="backStep">
                <v-icon large> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn icon color="#2E58EB" text large @click="nextStep">
                <v-icon large> mdi-chevron-right </v-icon>
              </v-btn>
            </div>
            <v-card-text
              class="text-subtitle-1 font-weight-black text-center whitebr"
            >
              {{ $t("video_skip") }}
            </v-card-text>
            <div class="ma-4 mt-8 video-panel-box">
              <video
                id="signupVideo"
                ref="signupVideo"
                preload="auto"
                @playing="updatePaused"
                @pause="updatePaused"
                @mouseover="showControl = true"
                @mouseleave="showControl = false"
                @ended="exitVideo"
                controls
                playsinline
                webkit-playsinline
                autoplay
                volume="0.5"
                oncontextmenu="return false;"
                controlsList="nodownload"
              >
                <source :src="videoUrl" type="video/mp4" />
                {{ $t("not_support_html5") }}
              </video>
              <div class="d-flex justify-center">
                <v-btn
                  :disabled="!email || !password"
                  outlined
                  autofocus
                  width="60%"
                  class="cont-button"
                  color="#2E58EB"
                  dark
                  @dblclick="noDouble()"
                  @click="skipVideo"
                >
                  {{ $t("skip") }}
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-window-item>
        <v-window-item :value="3">
          <v-card class="pa-5 signup-card flex-column">
            <div class="navbuttongroup">
              <v-btn icon large text color="#2E58EB" @click="backStep">
                <v-icon large> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn icon color="#2E58EB" text large @click="nextStep">
                <v-icon large> mdi-chevron-right </v-icon>
              </v-btn>
            </div>
            <v-card-text
              class="text-subtitle-1 font-weight-bold text-center ma-0"
            >
              {{ $t("welcome", { name: name }) }}
              <p class="text-subtitle-1 font-weight-black text-center ma-0">
                {{ $t("teamlist_msg") }}
              </p>
              <p
                class="text-subtitle-1 font-weight-normal text-center ma-0 mt-2"
              >
                {{ $t("teamlist_sub_msg") }}
              </p>
            </v-card-text>

            <div class="ma-0 pa-0 teampanel-box">
              <v-list color="rgba(0, 0, 0, 0)" class="checklist-item">
                <v-list-item-group v-model="selectedUsers" multiple>
                  <template v-for="(item, i) in teamlist">
                    <v-list-item
                      :key="`item-${i}`"
                      :value="item"
                      :ripple="false"
                      :disabled="item.is_selected_before ? true : false"
                      @click="
                        item.is_checked === 1
                          ? (item.is_checked = 0)
                          : (item.is_checked = 1)
                      "
                    >
                      <v-list-item-action>
                        <v-checkbox
                          :true-value="1"
                          :false-value="0"
                          :ripple="false"
                          v-model="item.is_checked"
                          color="#41BEA9"
                          @click="
                            item.is_checked === 1
                              ? (item.is_checked = 0)
                              : (item.is_checked = 1)
                          "
                        />
                      </v-list-item-action>
                      <v-list-item-content class="checkbox_item">
                        <v-list-item-title v-text="item.name" />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </div>
            <div class="d-flex justify-space-around ma-4">
              <v-btn
                outlined
                width="100%"
                class="mt-8 cont-button"
                color="#2E58EB"
                dark
                :loading="isLoading"
                @dblclick="noDouble()"
                @click="enterpriseStart"
              >
                {{ $t("continue") }}
              </v-btn>
            </div>
          </v-card>
        </v-window-item>
        <v-window-item :value="4">
          <v-card class="pa-5 signup-card">
            <div class="navbuttongroup">
              <v-btn icon large text color="#2E58EB" @click="backStep">
                <v-icon large> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn icon color="#2E58EB" text large @click="nextStep">
                <v-icon large> mdi-chevron-right </v-icon>
              </v-btn>
            </div>
            <div class="navbuttongroup">
              <v-btn icon large text color="#2E58EB" @click="backStep">
                <v-icon large> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn icon color="#2E58EB" text large @click="nextStep">
                <v-icon large> mdi-chevron-right </v-icon>
              </v-btn>
            </div>
            <div class="container">
              <div class="row d-flex align-items-center">
                <div class="col-12 col-sm-6 align-self-center">
                  <img
                    class="mobilapp"
                    width="100%"
                    src="@/assets/mobilapp.png"
                  />
                </div>
                <div
                  class="col-12 col-sm-6 d-flex flex-column align-self-center pa-4"
                >
                  <div class="steps">
                    {{ $t("signup_steps") }}
                  </div>
                  <div class="pin-numbers">
                    <span class="pinbox">{{ pinpassword[0] }}</span>
                    <span class="pinbox">{{ pinpassword[1] }}</span>
                    <span class="dash">{{ "-" }}</span>
                    <span class="pinbox">{{ pinpassword[2] }}</span>
                    <span class="pinbox">{{ pinpassword[3] }}</span>
                  </div>
                  <div class="timerbox">
                    <div class="timer-message">
                      {{
                        $t("time_left") +
                        minutes.toString().padStart(2, "0") +
                        " : " +
                        seconds.toString().padStart(2, "0")
                      }}
                    </div>
                    <v-btn
                      v-if="!timer"
                      color="#254FFB"
                      outlined
                      small
                      @click="resetPin"
                    >
                      <v-icon>mdi-refresh</v-icon>
                      {{ $t("regenerate_pin") }}
                    </v-btn>
                  </div>
                  <div class="errorbox" v-if="errormsg">
                    <div class="v-messages__message">
                      {{ errormsg }}
                    </div>
                  </div>
                  <div class="d-flex justify-center">
                    <a
                      href="https://itunes.apple.com/tr/app/journey-by-vibons/id1412829495?mt=8"
                      target="_blank"
                    >
                      <img
                        class="appicon mr-2"
                        height="40"
                        width="auto"
                        src="@/assets/iosapp.png"
                      />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.vibons.journeyapp"
                      target="_blank"
                    >
                      <img
                        class="appicon"
                        height="40"
                        width="auto"
                        src="@/assets/androidapp.png"
                      />
                    </a>
                  </div>
                  <div class="d-flex justify-space-around ma-4">
                    <v-btn
                      outlined
                      width="100%"
                      class="mt-8 cont-button"
                      color="#2E58EB"
                      dark
                      :loading="isLoading"
                      @dblclick="noDouble()"
                      @click="checkMobilStatus"
                    >
                      {{ $t("continue") }}
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-window-item>
        <v-window-item :value="5">
          <v-card class="pa-5 signup-card">
            <div class="navbuttongroup">
              <v-btn icon large text color="#2E58EB" @click="backStep">
                <v-icon large> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn
                icon
                color="#2E58EB"
                text
                large
                :disabled="true"
                @click="nextStep"
              >
                <v-icon large> mdi-chevron-right </v-icon>
              </v-btn>
            </div>
            <div class="container tebrik">
              <div
                class="row d-flex align-items-center tebrikrow justify-center"
              >
                <div
                  class="col-12 d-flex flex-column align-items-center justify-center"
                >
                  <img
                    class="checkicon"
                    height="63"
                    width="63"
                    src="@/assets/greencheck.png"
                  />
                  <div class="congralute">
                    {{ $t("congralute") }}
                  </div>

                  <div class="d-flex">
                    <v-btn
                      outlined
                      width="100%"
                      class="cont-button"
                      color="#2E58EB"
                      dark
                      @dblclick="noDouble()"
                      @click="startDevelopment"
                    >
                      {{ $t("start_development") }}
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-window-item>
      </v-window>
    </v-form>
  </div>
</template>

<script>
import Helper from "./../plugins/helper.vue";

import {
  API,
  API_ENTERPRISE_SIGNUP,
  API_ENTERPRISE_START,
  ENTERPRISE_VIDEO_URL,
  CLIENT_URL,
} from "./../plugins/config";

export default {
  name: "EnterpriseSignUp",
  mixins: [Helper],
  data: () => ({
    email: "",
    password: "",
    name: null,
    videoElement: null,
    paused: null,
    showControl: false,
    teamlist: [],
    selectedUsers: [],
    videoUrl: ENTERPRISE_VIDEO_URL,
    showMobilInstall: null,
    showStepInfo: false,
    togglePassword: false,
  }),

  watch: {
    steps(newValue) {
      if (newValue) {
        this.totalStep = 4;
        switch (this.steps) {
          case 1:
            this.stepsInfoTitle = "Create password";
            this.currentStep = 1;
            this.showStepInfo = true;
            break;
          case 2:
            this.stepsInfoTitle = "See how it works";
            this.currentStep = 2;
            break;
          case 3:
            this.stepsInfoTitle = "Select team members to develop";
            this.currentStep = 3;
            break;
          case 4:
            this.stepsInfoTitle = "Download app and login";
            this.currentStep = 4;
            break;
          case 5:
            this.stepsInfoTitle = "Download app and login";
            this.currentStep = 4;
            break;
          default:
            break;
        }

        if (newValue === 2) {
          if (this.videoElement) {
            this.videoElement.play();
          }
        } else {
          if (this.videoElement) {
            this.videoElement.pause();
          }
          this.videoUrl = null;
        }
      }
    }
  },

  mounted() {
    const { lang } = this.getParams(window.location.href);
    if (lang) this.$i18n.locale = lang;

    setTimeout(() => {
      this.steps = 1;
    }, 750);
  },

  methods: {
    async nextStep() {
      await this.sleep(500);
      if (this.steps === 1 && !this.email && !this.password) {
        return;
      }
      const isValid = await this.checkValidation();
      if (isValid) {
        this.steps += 1;
      }
    },

    async backStep() {
      await this.sleep(500);
      if (this.steps === 4) {
        this.steps -= 1;
        return;
      }
      const isValid = await this.checkValidation();
      if (isValid) {
        this.steps -= 1;
      }
    },

    updatePaused(event) {
      this.videoElement = event.target;
      this.paused = event.target.paused;
    },

    skipVideo() {
      if (this.videoElement) {
        this.videoElement.pause();
      }
      this.steps = 3;
    },

    exitVideo() {
      setTimeout(() => {
        this.steps = 3;
      }, 3000);
    },

    async signup() {
      this.isLoading = true;
      const isValid = await this.checkValidation();
      if (this.email && this.password && isValid) {
        const preparePost = {};
        preparePost.email = this.email;
        preparePost.password = this.password;
        const self = this;
        await API.post(API_ENTERPRISE_SIGNUP, preparePost, {
          headers: {
            "content-type": "application/json"
          }
        })
          .then(response => {
            if (response.data.StatusCode === 200) {
              self.isLoading = false;
              self.token = response.data.Token;
              self.name = response.data.ManagerName;
              const teamlist = response.data.Team;
              self.teamlist = teamlist.map(item => {
                const $item = item;
                $item.is_checked = item.is_selected_before;
                return $item;
              });
              self.steps = 2;
            }
            self.isLoading = false;
          })
          .catch(error => {
            self.displayError(error.response.data.StatusMessage);
            self.isLoading = false;
          });
      }
    },

    enterpriseStart() {
      this.isLoading = true;
      const payload = {};
      payload.selected_users = [];
      this.selectedUsers.forEach(item => {
        payload.selected_users.push(item.user_id);
      });
      API.post(`${API_ENTERPRISE_START}`, payload, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${this.token}`
        }
      })
        .then(response => {
          if (response.data.StatusCode === 200) {
            this.showMobilInstall = response.data.ShowMobileInstall;
            if (this.showMobilInstall) {
              this.getPincode().then(() => {
                this.showTimer();
                this.steps += 1;
              });
            } else {
              this.removeListener().then(()=> {
                  window.location.href = CLIENT_URL + "/?token=" + this.token;
              });
            }
            this.isLoading = false;
          }
        })
        .catch(error => {
          this.isLoading = false;
          this.displayError(error.response.data.StatusMessage);
        });
    },

    startDevelopment() {
      this.removeListener().then(()=> {
        window.location.href = CLIENT_URL + "/?token=" + this.token;
      });
    },

    noDouble() {
      console.log("Empty double click handler..");
    },

    goRetry() {
      window.location.reload();
    },

    checkNeedEmptyField(index) {
      if (
        this.position === "Manager" &&
        index > 1 &&
        index < 5 &&
        this.members.length < 6
      ) {
        this.members.push("");
      }
      if (this.members.length > 3) {
        this.members = this.members.filter(str => str.trim().length > 0);
        if (this.members.length < 6) {
          this.members.push("");
        }
      }
      if (this.members.length < 3) {
        this.members.push("");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.signup-page {
  width: 100vw;
  height: 100vh;
  padding: 0 !important;
  margin: 0 !important;
  color: #363636;
  overflow: hidden;
  position: absolute !important;
  box-shadow: none !important;
  min-height: 100vh !important;
  min-width: none !important;
  // background-color: #e6f2fe;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center bottom;
  // background-image: url("~@/assets/login_bg.png");
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .v-btn {
    text-transform: none;
  }
  .site-logo {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100px;
  }
  .mywindow {
    width: 100vw;
    min-height: 50vh;
    padding: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .signup-card {
    width: 50vw;
    max-width: 630px;
    height: 100%;
    min-height: 430px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff85;
    backdrop-filter: blur(6px);
    box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.25);
    border-radius: 13px;
    overflow-y: auto;
    .row {
      min-height: 360px;
    }
  }
  .panel-box {
    width: 60%;
  }
  .teampanel-box {
    width: 60%;
    padding: 0 20px 0 20px;
    max-height: 40vh;
    overflow-y: auto;
    .v-list-item {
      background: #eff1f3;
      margin-bottom: 5px;
    }
    .v-item-group {
      padding: 0 10px 0 10px;
    }
  }
  .steps {
    white-space: pre-line;
    font-family: Karla;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #363636;
  }
  .dash {
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 35px;
    color: #000000;
    opacity: 0.3;
  }
  .pin-numbers {
    display: flex;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 35px;
    color: #000000;
    margin: 35px auto;
    align-items: center;
    .pinbox {
      background: #ffffff;
      border: 0.7px solid #d3d4d5;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 7px;
      margin: 5px;
      padding: 5px;
      font-style: normal;
      font-weight: normal;
      font-size: 30px;
      line-height: 35px;
      color: #000000;
    }
  }
  .checkicon {
    margin: 0 auto;
  }
  .congralute {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #000000;
    margin: 20px auto;
  }
  .errorbox {
    margin: -15px auto;
    margin-bottom: 10px;
  }
  .cont-button {
    text-transform: none !important;
    background: #f6f6f6;
    margin-top: 50px;
    min-width: 300px !important;
  }
  .retry-button {
    color: #ff5252 !important;
  }

  .entry-input {
    margin-bottom: -15px;
    .v-input__control {
      .v-input__slot {
        width: 90% !important;
        min-width: 320px;
        margin: 0 auto;
      }
      .v-text-field__details {
        width: 90% !important;
        min-width: 320px;
        margin: 0 auto;
      }
    }
  }
  .panel-link-div {
    background: #ffffff;
    border: 1px solid #2e58eb;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .panel-share-text {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      margin-left: 10px;
      line-height: 14px;
      min-width: 200px;
      text-align: center;
      color: #2e58eb;
    }
    .shareButton {
      background: #2e58eb;
      border: 1px solid #2e58eb;
      box-sizing: border-box;
      width: 80px;
      border-radius: unset;
    }
  }
  .tebrik {
    width: 100%;
  }
  .tebrikrow {
    width: 50%;
    margin: 0 auto;
  }
  .steps-info {
    display: flex;
    position: absolute;
    top: 10%;
    left: 20%;
    z-index: 100;
    font-family: Karla;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
  }
}

@media screen and (max-width: 720px) {
  .signup-page,
  .mywindow {
    height: 100% !important;
    max-height: none !important;
    align-items: flex-start;
    overflow: auto;
  }
  .site-logo {
    margin-top: 20px;
  }
  .signup-card {
    width: 90vw !important;
    height: 100% !important;
    max-height: none !important;
    overflow: auto;
  }
  .mobilapp {
    max-height: 250px;
    width: 100%;
    object-fit: contain;
  }
}

.v-list-item--link:before {
  background-color: transparent;
}
.timerbox {
  margin: -15px auto;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .timer-message {
    color: #ff5252 !important;
  }
}
.navbuttongroup {
  position: absolute;
  top: 15px;
  left: 15px;
}
.leftme {
  position: absolute !important;
  right: 2px;
}
video {
  width: 100%;
  z-index: 10;
  position: relative;
}
.btn-forward {
  position: fixed;
  bottom: 100px;
  right: 100px;
  background: rgba(255, 255, 255, 0.1) !important;
  border: 1px solid rgba(255, 255, 255, 0.5) !important;
  box-sizing: border-box;
  border-radius: 3px;
  text-transform: none !important;
  z-index: 2000;
}
.icon-forward {
  background: #6d5ab6;
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  border-radius: 3px;
}
.theme--dark.v-btn.v-btn--disabled {
  color: rgba(102, 100, 100, 0.7) !important;
}
.video-panel-box {
  width: 100%;
}
.whitebr {
  white-space: pre-line;
}
::v-deep i.mdi-checkbox-blank-outline {
  color: transparent !important;
  background: #4592d1 !important;
  border-radius: 4px !important;
  font-size: 34px;
}
::v-deep i.mdi-checkbox-marked {
  background: #fff !important;
  color: #41bea9 !important;
  font-size: 34px;
}
.notice {
  font-size: 12px;
  color: rgba(102, 100, 100, 0.7) !important;
  margin-top: 20px;
  text-align: center;
}
</style>